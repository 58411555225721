<template>
  <b-row>
    <b-col cols="12">
      <b-card-group
        columns
        class="mb-0"
      >
        <b-card
          v-for="auction in auctions.data"
          :key="auction.id"
          :img-src="'https://erp.vergosauctions.com/uploads/auctions/' + auction.project_main_image"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-title>{{ auction.text }}</b-card-title>
            <b-card-text>
              {{ auction.auction_slider_text }}
            </b-card-text>
          </b-card-body>
          <b-card-footer>
            <b-button @click="loadOffers(auction.id)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              Εμφάνιση προσφορών
            </b-button>
          </b-card-footer>
        </b-card>
      </b-card-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  computed: {
    auctions() {
      return this.$store.getters['data/getAuctions']
    },
  },
  methods: {
    // eslint-disable-next-line camelcase
    loadOffers(auction_id) {
      this.$store.dispatch('data/fetchAuctionOffers', auction_id).then(() => {
        this.$router.push('/offers')
      })
    },
  },
  mounted() {
    this.$store.dispatch('data/fetchAuctions')
  },
}
</script>
